// @ts-nocheck
import React, { useState } from "react";
import "../styles/App.css";
import bridgeSymbol from "../assets/bridge-symbol.png";
import rightArrow from "../assets/arrow-right-icon.svg";
import mesh from "../assets/mesh.png";
import twitter from "../assets/twitter-icon.svg";
import useRainbow from "../utils/useRainbowHook";
import { Method, CallAPI } from "../utils/callAPI";

function App() {
	const [emailInput, setEmailInput] = useState(false);
	const [email, setEmail] = useState("");
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(null);
	const intervalDelay = 2000;
	const transitionDelay = intervalDelay * 1.25;
	const colors = useRainbow({ intervalDelay });
	const colorKeys = Object.keys(colors);

	const waitlistStyle = {
		lineHeight: "50px",
		height: "50px",
		padding: "0 20px",
		fontSize: "18px",
		fontWeight: "500",
		transition: "cubic-bezier(0,0,.3,1) 150ms",
		borderRadius: "10px",
	};

	return (
		<div
			className="gradient-wrapper"
			style={{
				...colors,
				transition: `
        ${colorKeys[0]} ${transitionDelay}ms linear,
        ${colorKeys[1]} ${transitionDelay}ms linear,
        ${colorKeys[2]} ${transitionDelay}ms linear
      `,
				background:
// 				 colorKeys.length
// 					? `
// 	radial-gradient(
// 	  circle at top left,
// 	  var(${colorKeys[2]}),
// 	  var(${colorKeys[1]}),
// 	  var(${colorKeys[0]})
// 	)
//   `
// 					: 
					`url(${mesh}) no-repeat center center`,
				backgroundSize: "cover",
			}}
		>
			<a
				className="email-link"
				href="https://twitter.com/bridgelabsxyz"
				target="_blank"
				rel="noopener noreferrer"
				style={{
					position: "fixed",
					top: "0",
					textAlign: "center",
					fontSize: "14px",
					letterSpacing: "0.28px",
					lineHeight: "35px",
					height: "35px",
					marginTop: "35px",
					padding: "0 15px 0 10px",
					borderRadius: "20px",
					left: "50%",
					transform: "translateX(-50%)",
					transition: "cubic-bezier(0,0,.3,1) 150ms",
					verticalAlign: "top",
					fontWeight: "500"
				}}
			>
				<img
					style={{
						display: "inline-block",
						width: "24px",
						height: "35px",
						margin: "0 5px 0 0",
						verticalAlign: "top",
					}}
					src={twitter}
					alt="Twitter Icon"
				/>
				@bridgelabsxyz
			</a>

			<div
				style={{
					position: "absolute",
					zIndex: "9999",
					left: "50%",
					top: "50%",
					transform: "translateY(-50%) translateX(-50%)",
					textAlign: "center",
					width: "100%",
				}}
			>
				{!success && (
					<img
						style={{
							display: "block",
							width: "100px",
							height: "100px",
							margin: "0 auto",
						}}
						src={bridgeSymbol}
						alt="Bridge Logo"
					/>
				)}
				<div
					style={{
						marginTop: "50px",
					}}
				>
					{/* {!emailInput && (
						<button
							className="waitlist"
							style={{
								...waitlistStyle,
								...{ cursor: "pointer" },
							}}
							onClick={() => {
								setEmailInput(true);
							}}
						>
							Join the Bridge Beta
						</button>
					)}
					{emailInput && ( */}
						<>
							{!success && (
								<div style={{}}>
									<div>
										<input
											className="waitlist"
											type="text"
											inputMode="text"
											style={{
												...waitlistStyle,
												...{
													width: "250px",
													display: "inline-block",
													overflow: "hidden",
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
												},
											}}
											placeholder="Email for waitlist"
											maxLength={254}
											value={email}
											onChange={(e) => {
												setEmail(e.target.value);
											}}
											autocomplete="off"
											// autoCompleteType="email"
											// name="email"
										></input>
										<button
											className="waitlist"
											style={{
												...waitlistStyle,
												...{
													cursor: "pointer",
													marginLeft: "15px",
													display: "inline-block",
													width: "50px",
													verticalAlign: "top",
													padding: "0",
												},
											}}
											onClick={async () => {
												try {
													const add = await CallAPI.call(
														Method.POST,
														"https://bridge-6pcgl.ondigitalocean.app/email/addMember",
														{ email: email }
													);
													if (add.status !== "subscribed") {
														let err = JSON.parse(add.response.text).title;
														switch (err) {
															case "Member Exists": {
																err = "Already Registered";
																break;
															}
															case "Invalid Resource": {
																err = "Invalid Email";
																break;
															}
														}
														setError(err);
													} else setSuccess(true);
												} catch (e) {
													console.log(e);
													// setError(err);
												}
											}}
										>
											<img
												style={{
													display: "block",
													width: "24px",
													height: "24px",
													margin: "0 auto",
												}}
												src={rightArrow}
												alt="Submit Email"
											/>
										</button>
									</div>
									{error && (
										<p
											style={{
												color: "#393940",
												position: "absolute",
												left: "50%",
												transform: "translateX(-50%)",
												fontWeight: "500",
												fontSize: "16px",
												lineHeight: "40px",
											}}
										>
											Error — {error}
										</p>
									)}
								</div>
							)}
							{success && (
								<div className="fade-in">
									<p
										style={{
											maxWidth: "300px",
											fontSize: "18px",
											lineHeight: "27px",
											textAlign: "center",
											margin: "0 auto",
										}}
									>
										Thank you for joining the waitlist.
									</p>
									{/* <p
										style={{
											maxWidth: "300px",
											fontSize: "18px",
											lineHeight: "27px",
											textAlign: "center",
											margin: "0 auto",
											marginTop: "15px",
										}}
									>
										We will send you a link to try Bridge within the next few
										weeks.
									</p> */}
									<a
										className="waitlist"
										href="https://tally.so/r/mJ92Po"
										target="_blank"
										rel="noopener noreferrer"
										style={{
											...waitlistStyle,
											...{
												cursor: "pointer",
												display: "inline-block",
												marginTop: "50px",
											},
										}}
									>
										Get access sooner
									</a>
									<p
										style={{
											fontSize: "14px",
											letterSpacing: "0.28px",
											lineHeight: "14px",
											marginTop: "15px",
										}}
									>
										1-2 min to complete
									</p>
								</div>
							)}
						</>
					{/* )} */}
				</div>
			</div>

			<a
				className="email-link"
				href="mailto: hello@bridgelabs.xyz"
				style={{
					position: "fixed",
					bottom: "0",
					textAlign: "center",
					fontSize: "14px",
					letterSpacing: "0.28px",
					lineHeight: "35px",
					height: "35px",
					marginBottom: "35px",
					padding: "0 15px",
					borderRadius: "20px",
					left: "50%",
					transform: "translateX(-50%)",
					transition: "cubic-bezier(0,0,.3,1) 150ms",
					fontWeight: "500"
				}}
			>
				hello@bridgelabs.xyz
			</a>
			{/* <canvas id="gradient-canvas" data-transition-in></canvas> */}
		</div>
	);
}

export default App;
