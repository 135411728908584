import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
	BrowserRouter as Router,
	HashRouter,
	Routes,
	Route,
} from "react-router-dom";

import App from "./views/App";
import reportWebVitals from "./utils/reportWebVitals";
import "./styles/fonts.css";

// const Webstore = () => {
// 	useEffect(() => {
// 		console.log("webstore");
// 		window.location.replace(
// 			"https://chrome.google.com/webstore/detail/bridge/fgbmbakdpbffmdfjiphglafgbmcjlfgi"
// 		);
// 	}, []);
// 	return <></>;
// };

// const Privacy = () => {
// 	useEffect(() => {
// 		window.location.replace("https://bridgelabs.xyz/privacy.html");
// 	}, []);
// 	return <></>;
// };

const Webstore = () => {
	window.location.replace(
		"https://chrome.google.com/webstore/detail/bridge/fgbmbakdpbffmdfjiphglafgbmcjlfgi"
	);
	return null;
};

const Privacy = () => {
	window.location.replace("https://bridgelabs.xyz/privacy.html");
	return null;
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<Router>
		<Routes>
			{/* <Route path="/privacy" element={<Privacy />} />
			<Route path="/download" element={<Webstore />} /> */}
			<Route path="/" element={<App />} />
			<Route element={<App />} />
		</Routes>
	</Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
