/* eslint-disable array-callback-return */
import React from "react";

import { range, generateId } from "./gradientUtils";
import useIncrementingNumber from "./gradientHooks";

const rainbowColors = [
	"#4CDAB7",
	"#55F2E5",
	"#55D8F2",
	"#6D6DF2",
	"#8561F2",
	"#A355F2",
	"#5555F2",
];
const paletteSize = rainbowColors.length;
const WINDOW_SIZE = 3;

// During compile-time build, we have to assume no browser support.
// On mount, we'll check if `CSS.registerProperty` exists
const hasBrowserSupport =
	typeof window !== "undefined"
		? typeof window.CSS.registerProperty === "function"
		: false;

const getColorPropName = (id, index) => `--magic-rainbow-color-${id}-${index}`;

const useRainbow = ({ intervalDelay = 2000 }) => {
	const prefersReducedMotion =
		typeof window === "undefined"
			? true
			: window.matchMedia("(prefers-reduced-motion: no-preference)");

	const isEnabled = hasBrowserSupport && prefersReducedMotion.matches;

	const { current: uniqueId } = React.useRef(generateId());

	// Register all custom properties
	React.useEffect(() => {
		if (!isEnabled) return;

		range(0, WINDOW_SIZE).map((index) => {
			const name = getColorPropName(uniqueId, index);
			const initialValue = rainbowColors[index];

			try {
				CSS.registerProperty({
					name,
					initialValue,
					syntax: "<color>",
					inherits: false,
				});
			} catch (e) {}
		});
	}, [uniqueId, isEnabled]);

	const intervalCount = useIncrementingNumber(intervalDelay);

	if (isEnabled) {
		return range(0, WINDOW_SIZE).reduce((acc, index) => {
			const effectiveIntervalCount = isEnabled ? intervalCount : 0;

			const name = getColorPropName(uniqueId, index);
			const value =
				rainbowColors[(effectiveIntervalCount + index) % paletteSize];

			return {
				...acc,
				[name]: value,
			};
		}, {});
	} else {
		return {};
	}
};

export default useRainbow;
