export const Method = {
	GET: "GET",
	POST: "POST",
	PUT: "PUT",
	PATCH: "PATCH",
	DELETE: "DELETE",
}

export class CallAPI {
	static async call(
		method,
		url,
		body,
		headers = new Headers()
	) {
		headers.append("Content-Type", "application/json");

		console.log("body: " + body);

		return fetch(url, {
			method: method,
			mode: "cors",
			headers: headers,
			body: JSON.stringify(body),
		})
			.then(async (response) => {
				console.log("status: " + response.status);
				return response.text();
			})
			.then((text) => {
				try {
					const data = JSON.parse(text);
					console.log(data);
					return data;
				} catch (err) {
					console.log(text);
					return text;
				}
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}
}
